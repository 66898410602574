// → styled google map for contact page

import React from "react";
import styled from "styled-components";
import { GoogleMap, LoadScript, InfoWindow } from "@react-google-maps/api";
import { mainBlue, mainWhite, screen } from "../common/variables";
import mapStyle from "./style";

const Wrapper = styled.div`
  #google-map {
    width: 100%;
    height: 400px;
    display: block;
    @media ${screen.xsmall} {
      height: 685px;
    }
    @media ${screen.small} {
      height: 660px;
    }

    .box-wrapper {
      background-color: ${mainBlue};
      padding: 16px 15px;
      @media ${screen.xsmall} {
        padding: 26px 24px;
      }

      &__heading {
        color: ${mainWhite};
        font-weight: 900;
        font-size: 1.2rem;
        @media ${screen.xsmall} {
          font-size: 2.5rem;
        }
      }

      &__address {
        color: ${mainWhite};
        font-size: 0.9rem;
        @media ${screen.xsmall} {
          font-size: 1.25rem;
        }
      }

      &__arrow {
        max-width: 21px;
        display: block;
        margin: -3px auto 0 auto;
        @media ${screen.small} {
          max-width: 23px;
        }
      }
    }

    .gm-style-iw-c {
      padding: 0;
      border-radius: 0;
    }

    .gm-style-iw-d {
      overflow: hidden !important;
    }

    .gm-style-iw-t::after {
      background: ${mainBlue};
      left: -84px;
      height: 20px;
      width: 20px;
      @media ${screen.xsmall} {
        height: 28px;
        width: 28px;
        left: -119px;
      }
    }

    button {
      display: none !important;
    }
  }
`;

const Map = () => {
  return (
    <Wrapper>
      <LoadScript googleMapsApiKey={process.env.GATSBY_GOOGLE_API_KEY}>
        <GoogleMap
          id="google-map"
          options={{
            styles: mapStyle,
            disableDefaultUI: true,
            gestureHandling: "greedy",
          }}
          zoom={16}
          center={{ lat: -33.6655231, lng: 150.8601573 }}
        >
          <InfoWindow position={{ lat: -33.6655231, lng: 150.8601573 }}>
            <div className="box-wrapper">
              <a
                href="https://www.google.com/maps/place/46+Loftus+St,+Riverstone+NSW+2765/@-33.6655389,150.8579603,17z/data=!4m5!3m4!1s0x6b129dd86d77a17f:0x1189324f045fc63b!8m2!3d-33.6655231!4d150.8601573"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="View Google Map"
              >
                <p className="box-wrapper__heading">Visit ATLC</p>
                <p className="box-wrapper__address">
                  46 - 48 Loftus Street, Riverstone
                  <br /> Australia NSW 2765
                </p>
              </a>
            </div>
          </InfoWindow>
        </GoogleMap>
      </LoadScript>
    </Wrapper>
  );
};

export default Map;
