import React from "react";
import { graphql } from "gatsby";
import {
  mainBlue,
  mainWhite,
  mainOrange,
  transHover,
  screen,
} from "../components/common/variables";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/page-title";
import LabeledArrow from "../components/labeled-arrow";
import GoogleMap from "../components/google-map";
import Obfuscate from "react-obfuscate";
import Form from "../components/form";

const Wrapper = styled.div`
  padding-bottom: 110px;
  padding-top: 73px;
  @media ${screen.xsmall} {
    padding-top: 87px;
    padding-bottom: 170px;
  }
  @media ${screen.small} {
    padding-top: 80px;
  }
  @media ${screen.xlarge} {
    padding-bottom: 200px;
  }

  .content {
    color: ${mainBlue};
    display: flex;
    flex-direction: column;
    padding-top: 35px;
    background-color: ${mainWhite};
    position: relative;
    @media ${screen.xsmall} {
      padding-top: 40px;
    }
    @media ${screen.small} {
      justify-content: space-between;
      flex-direction: row;
      padding-top: 82px;
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-top: 100px;
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.xlarge} {
      padding-left: 280px;
      padding-right: 280px;
    }

    &__text {
      max-width: 450px;
      width: 100%;
      margin-right: 60px;
      margin-bottom: 60px;
      padding-left: 35px;
      padding-right: 35px;
      @media ${screen.xsmall} {
        padding-left: 70px;
        padding-right: 70px;
        margin-bottom: 190px;
      }
      @media ${screen.small} {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
        max-width: 400px;
      }
      @media ${screen.medium} {
        max-width: 380px;
      }

      .contact-heading {
        line-height: 1.25;
      }

      .contact-description {
        font-size: 1.17rem;
        font-weight: 300;
        margin-bottom: 34px;
        margin-top: 20px;
        @media ${screen.xsmall} {
          font-size: 1.4rem;
          margin-bottom: 40px;
        }
        @media ${screen.xlarge} {
          font-size: 1.5rem;
          margin-top: 25px;
          margin-bottom: 50px;
        }

        &--bottom {
          margin-bottom: 0;
        }
      }

      .contact-link {
        margin-top: 24px;
      }
    }

    /* just for tablet size */
    .contact-details {
      display: none;
      @media ${screen.xsmall} {
        background-color: ${mainOrange};
        color: ${mainWhite};
        padding: 25px 60px;
        position: absolute;
        right: 30px;
        display: block;
      }
      @media ${screen.small} {
        display: none;
      }

      &__heading {
        font-size: 2.3rem;
        font-weight: 800;
        margin-bottom: 20px;
      }

      &__label {
        display: block;
        font-weight: 900;
        font-size: 0.87rem;
      }

      a {
        color: ${mainWhite};
        font-size: 1.25rem;
        margin-bottom: 20px;
        margin-top: 4px;
        display: table;
        transition: ${transHover};

        @media ${screen.withCursor} {
          &:hover {
            color: ${mainBlue};
          }
        }
      }
    }
  }

  #map-container {
    /* max-width: 1360px; */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    position: relative;
    display: flex;
    flex-direction: column;

    @media ${screen.xsmall} {
      margin-top: 50px;
      display: block;
      margin-top: -20px;
    }
    @media ${screen.small} {
      margin-top: -20px;
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-left: 0;
      padding-right: 0;
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.xlarge} {
      padding-left: 280px;
      padding-right: 280px;
    }

    .contact-details {
      background-color: ${mainOrange};
      color: ${mainWhite};
      margin-left: 35px;
      margin-right: 35px;
      margin-top: 50px;
      padding: 25px 35px;
      max-width: 320px;
      @media ${screen.xsmall} {
        display: none;
      }
      @media ${screen.small} {
        display: block;
        max-width: 360px;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        padding: 42px;
        position: absolute;
        top: -50px;
        left: 50px;
        order: 0;
        width: 100%;
      }
      @media ${screen.medium} {
        display: block;
        left: 160px;
        top: -86px;
      }
      @media ${screen.large} {
        left: 160px;
      }
      @media ${screen.xlarge} {
        left: 280px;
      }

      &__heading {
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 25px;
        @media ${screen.xsmall} {
          font-size: 2.5rem;
          margin-bottom: 40px;
        }
      }

      &__label {
        display: block;
        font-size: 0.8rem;
        font-weight: 900;
        @media ${screen.xsmall} {
          font-size: 0.87rem;
        }
      }

      a {
        color: ${mainWhite};
        font-size: 1.05rem;
        margin-bottom: 20px;
        margin-top: 4px;
        display: inline-block;
        transition: ${transHover};
        @media ${screen.xsmall} {
          font-size: 1.25rem;
        }
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainBlue};
          }
        }
      }
    }
  }
`;

const ContactPage = ({ data }) => {
  const {
    phone,
    sales_email,
    management_email,
    account_email,
    lance_email,
  } = data.general.data;

  return (
    <Layout>
      <SEO
        title={`Contact Us | Get In Touch | Australian Thermic Lance Company`}
      />
      <Wrapper>
        <div className="content">
          <div className="content__text">
            <PageTitle className="contact-heading">Get in touch</PageTitle>
            <p className="contact-description">
              Fill in the form to ask us about our products and services.
            </p>
            <p className="contact-description contact-description--bottom">
              To receive a product-specific price quote and distributor
              information
            </p>
            <LabeledArrow
              className="contact-link"
              label={`SUBMIT ENQUIRY`}
              url={`/enquiry`}
            />
          </div>
          <Form />

          <div className="contact-details">
            <h6 className="contact-details__heading">Say hello</h6>
            <span className="contact-details__label">PHONE</span>
            <a href={`tel:${phone}`} aria-label="Call Phone Number">
              {phone}
            </a>
            <span className="contact-details__label">SALES INFORMATION</span>
            <Obfuscate email={sales_email}>{sales_email}</Obfuscate>
            <span className="contact-details__label">MANAGEMENT</span>
            <Obfuscate email={management_email}>{management_email}</Obfuscate>
            <span className="contact-details__label">ACCOUNT INFORMATION</span>
            <Obfuscate email={account_email}>{account_email}</Obfuscate>
            <span className="contact-details__label">LANCE QUESTION</span>
            <Obfuscate email={lance_email}>{lance_email}</Obfuscate>
          </div>
        </div>
        <div id="map-container">
          <GoogleMap />

          <div className="contact-details">
            <h6 className="contact-details__heading">Say hello</h6>
            <span className="contact-details__label">PHONE</span>
            <a href={`tel:${phone}`} aria-label="Call Phone Number">
              {phone}
            </a>
            <span className="contact-details__label">SALES INFORMATION</span>
            <Obfuscate email={sales_email}>{sales_email}</Obfuscate>
            <span className="contact-details__label">MANAGEMENT</span>
            <Obfuscate email={management_email}>{management_email}</Obfuscate>
            <span className="contact-details__label">ACCOUNT INFORMATION</span>
            <Obfuscate email={account_email}>{account_email}</Obfuscate>
            <span className="contact-details__label">LANCE QUESTION</span>
            <Obfuscate email={lance_email}>{lance_email}</Obfuscate>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default ContactPage;

export const dataQuery = graphql`
  {
    general: prismicGeneralDetails {
      data {
        phone
        sales_email
        management_email
        account_email
        lance_email
      }
    }
  }
`;
